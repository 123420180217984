<template>
  <div class="lg:p-0 p-3">
    <h3 class="page-title">Contact Us</h3>
    <span class="dropdown" :class="{ shown: state }">
      <div class="dropdown-menu" v-show="state">
        <ul class="list-unstyled">
          <slot></slot>
        </ul>
      </div>
      <transition />
    </span>
    <div class="lg:grid grid-cols-2 gap-4">
      <div class="left-column">
        <div class="card">
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#map-marker"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">Our Address</div>
              <p>1140 Wehrle Drive, Buffalo, NY 14221</p>
            </div>
          </div>
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#phone"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">Contact</div>
              <p>(800) 554-3734</p>
            </div>
          </div>
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#email"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">Submit Invoices Packet</div>
              <p>payables@shipldi.com</p>
            </div>
          </div>
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#email"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">Submit Carrier Account Documents</div>
              <p>carrierdev@shipldi.com</p>
            </div>
          </div>
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#email"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">Verify Rate</div>
              <p>verifyrate@shipldi.com</p>
            </div>
          </div>
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#email"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">Payment Status</div>
              <p>paymentstatus@shipldi.com</p>
            </div>
          </div>
          <div class="contact-wrapper">
            <div class="icon-wrapper">
              <svg class="icon">
                <use xlink:href="#email"></use>
              </svg>
            </div>
            <div>
              <div class="contact-title">SUPPORT</div>
              <p>support@shipldi.com</p>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="card">
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Contact from "@/components/common/Contact.vue";
export default {
  data() {
    return {};
  },
  components: {
    Contact,
  },
};
</script>
<style lang="scss" scoped>
.card {
  height: 100%;
  label {
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 5px;
  }
}
.contact-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .icon-wrapper {
    background: #fafafa;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    .icon {
      width: 30px;
      height: 30px;
    }
  }
  .contact-title {
    font-size: 16px;
    font-weight: 700;
    color: #181818;
    text-transform: uppercase;
  }
}
</style>
